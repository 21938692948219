import React from 'react';
import {graphql} from 'gatsby';

import Layout from '../components/common/layout';
// import { Location } from "@reach/router";
// import Sidebar from '../components/common/Sidebar';

import Introduction from '../components/introduction/Introduction';
// import BodyCopy from '../components/BodyCopy/bodycopy';
import Artists from '../components/artists/Artists';
// import Features     from '../components/features/Features';
// import Capabilities from '../components/capabilities/Capabilities';
// import Contact      from '../components/contact/Contact';

const IndexPage = ({ lang, location, data }) => {
	
	const language = lang;
	let isEn = false;
	if (language === "en") {
		isEn = true;
	}
	const content = {
		curatedby: { en: "Curated by", is: "Sýningarstjórar" },
	};

	return (
		<Layout location={location}>
			{/* <Sidebar /> */}

			<div id="wrapper">
				<Introduction lang="is" location={location} />
				<section className="wrapper fade-up">
					<div
						className="inner"
						dangerouslySetInnerHTML={{
							__html: data.natureintransitionJson.content_is.childMarkdownRemark.html
						}}
					></div>
				</section>
				<div className="artist-list">
					<Artists artists={data.natureintransitionJson.artists} lang="is" location={location} />
				</div>
				<div className="curators">
					{isEn ? content.curatedby.en : content.curatedby.is}<br />
					Hanna Styrmisdóttir<br />
					Hulda Stefánsdóttir
				</div>
				<div className="images">
					Images go here
						<span className="caption">Caption goes here</span>
				</div>
				{/* <BodyCopy lang="is" location={location} data={data} /> */}
				{/* <Capabilities /> */}
				{/* <Contact /> */}
			</div>

		</Layout>
	)
};


export default IndexPage;


export const IndexIsQuery = graphql`
	query IndexIsIntro {
		natureintransitionJson {
			title
			content {
				childMarkdownRemark {
					html
					rawMarkdownBody
				}
			}
			content_is {
				childMarkdownRemark {
					html
					rawMarkdownBody
				}
			}
			artists {
				name
				country
				to {
					title
					href
					label
				}
			}
		}
	}
`