// import { StaticQuery } from 'gatsby';
import React from 'react';
// import Scroll from '../common/Scroll';
// import { Location } from "@reach/router";
// import LanguageSelector from '../common/LanguageSelector';
// import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"


const Introduction = ({lang, langKey, location}) => {
	const language = lang;
	let isEn = false;
	if (language === "en") {
		isEn = true;
	}
	const content = {
		title: { en: "Nature in Transition", is: "Í síkvikri mótun" },
		dates: { en: "From 17. 4. ’21", is: "Frá 17. 4. ’21" },
		location: {
			en: 'in Hvelfing at The Nordic House',
			is: 'í Hvelfingu Norræna hússins'
		}
	}
	return (
		<section id="intro" className="w-full md:relative md:grid md:grid-cols-8 md:grid-rows-1 mb-8">
			<div className="dates text-right text-base md:text-2xl w-auto md:absolute md:top-0 md:right-0">
				<span>{isEn ? content.dates.en : content.dates.is}</span><br />
				<span>{isEn ? content.location.en : content.location.is}</span>
			</div>
			<div className="titles text-3xl md:text-4xl md:row-start-1 md:col-span-6">
				{/* <img src={titleImage} alt="Nature in Transition Title" /> */}
				<StaticImage src="../../assets/img/NITSI_Title_02.png" alt="Nature in Transition" />
				<div className="sr-only">
				<div className="transform -skew-x-6 mt-4">
					<h1>Nature in Transition,<br />
					Shifting Identites</h1>
				</div>
				<div className="transform skew-x-6 mt-4">
					<h1>Í síkvikri mótun:<br />
					vitund og náttúra</h1>
				</div>
				<div className="transform -skew-x-12 mt-4">
					<h1>Kinaassuseq allanngujasoq —<br />
					pinngortitaq allangorartoq</h1>
				</div>
				<div className="transform skew-x-12 mt-4">
					<h1>Flydende Identitet,<br />
					Natur i Omformning</h1>
					</div>
				</div>
				{/* <ul className="actions">
					<li>
					<Link className="button" to="/underneath/">
					{isEn ? content.buttonText.en : content.buttonText.is}
					</Link>
					</li>
				</ul> */}
			</div>
		</section>
	);
};
	
export default Introduction;
